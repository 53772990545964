<!-- @format -->

<template>
  <b-card no-body>
    <div class="m-2">
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="10"
          md="12"
          sm="12"
          xl="8"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>每页条数</label>
          <v-select
            v-model="perPage"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button variant="primary" :to="{ name: 'policy_list_create' }">
            添加
          </b-button>

          <!-- <div class="wocc">
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret class="p-0">
              <template v-slot:button-content class="p-0" style="padding: 0">
                <b-button variant="primary" class="ml-1" v-if="piliangArray.length > 0"> 批量操作 </b-button>
              </template>
              <b-dropdown-item @click="deleteInvoicePl">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>批量删除</span>
              </b-dropdown-item>
              <b-dropdown-item @click.prevent.stop=";[$bvModal.show('audit-modal'), (checkForm.id = piliangArray)]">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>批量审核</span>
              </b-dropdown-item>
            </b-dropdown>
          </div> -->
        </b-col>

        <!-- Search -->
        <!-- <b-col cols="14" md="12" sm="12" class="mt-md-1" xl="4">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="请输入订单编号、保单编号、或会员ID" />
            <BFormDatepicker v-model="startTime" placeholder="请选择开始时间" class="d-inline-block mr-1" />
            <BFormDatepicker v-model="endTime" placeholder="请选择结束时间" class="d-inline-block mr-1" />
          </div>
        </b-col> -->
      </b-row>
    </div>

    <b-table
      v-if="geren == 0"
      bordered
      tbody-tr-class="text-center"
      :selectable="selectable"
      select-mode="multi"
      @row-clicked="contentChange"
      ref="orderListTable"
      :items="fetchInvoices"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="position-relative text-center"
    >
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&#x2610;</span>
          <span class="sr-only">Not Selected</span>
        </template>
      </template>

      <template #head(invoiceStatus)>
        <feather-icon icon="TrendingUpIcon" class="mx-auto" />
      </template>

      <!-- Column: Id -->
      <template #cell(id)="data">
        <div class="font-medium-1">{{ data.item.create_name }}</div>
        <div>{{ data.item.create_level }}</div>
        <div class="grid-hero" style="color: #ccc">
          {{ data.value }}
        </div>
      </template>

      <template #cell(number)="data">
        <div class="font-medium-1">{{ data.item.number }}</div>
        <div class="grid-hero" style="color: #ccc">
          面额: {{ data.item.money }} $
        </div>
      </template>

      <!-- Column: Invoice Status -->
      <template #cell(status)="data">
        <b-badge :variant="data.value | filterStatusColor" class="mx-auto">{{
          data.value | filterStatusTxt
        }}</b-badge>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(issuedDate)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <template #cell(share_number)="data">
        <b-link>
          #<span
            class="text-nowrap"
            @click="getMoneyInfo(data.item, data.item.id)"
          >
            {{ data.item.share_rate.length }}人
          </span>
        </b-link>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
          <template v-slot:button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="text-body align-middle mr-25"
            />
          </template>
          <!-- <b-dropdown-item @click="setItemEditCreate(data)">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span>添加付款</span>
          </b-dropdown-item>

          <b-dropdown-item @click="setItemEdit(data)">
            <feather-icon icon="Edit2Icon" class="mr-50" />
            <span>编辑</span>
          </b-dropdown-item> -->
          <b-dropdown-item @click="setItemDetail(data)">
            <feather-icon icon="EyeIcon" class="mr-50" />
            <span>详情</span>
          </b-dropdown-item>

          <!-- <b-dropdown-item @click="deleteInvoice(data.item.id)">
            <feather-icon icon="TrashIcon" class="mr-50" />
            <span>删除</span>
          </b-dropdown-item>
          <b-dropdown-item @click.prevent.stop=";[$bvModal.show('audit-modal'), (checkForm.id = data.item.id)]">
            <feather-icon icon="MessageCircleIcon" class="mr-50" />
            <span>审核</span>
          </b-dropdown-item> -->
        </b-dropdown>
        <!--        <div class="f-flex">-->
        <!--          &lt;!&ndash;      编辑    &ndash;&gt;-->
        <!--          <b-button size="sm" variant="primary" :to="{ name: 'policy_list_edit', params: { id: data.item.id } }" @click="$store.commit('user-order/setOrderDetail', data.item)">-->
        <!--            编辑-->
        <!--            <feather-icon icon="EditIcon" />-->
        <!--          </b-button>-->
        <!--          &lt;!&ndash;      删除    &ndash;&gt;-->
        <!--          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>-->
        <!--            <template v-slot:button-content>-->
        <!--              <b-button size="sm" variant="danger">-->
        <!--                删除-->
        <!--                <feather-icon icon="TrashIcon" />-->
        <!--              </b-button>-->
        <!--            </template>-->
        <!--            <b-dropdown-item>-->
        <!--              <feather-icon icon="TrashIcon" class="mr-50" />-->
        <!--              <span @click="deleteInvoice(data.item.id)">确认删除</span>-->
        <!--            </b-dropdown-item>-->
        <!--          </b-dropdown>-->
        <!--          &lt;!&ndash;          审核&ndash;&gt;-->
        <!--          <b-button size="sm" variant="success" @click.prevent.stop=";[$bvModal.show('audit-modal'), (checkForm.id = data.item.id)]">-->
        <!--            审核-->
        <!--            <feather-icon icon="CheckIcon" />-->
        <!--          </b-button>-->
        <!--        </div>-->
      </template>
    </b-table>
    <b-table
      v-if="geren == 1"
      bordered
      tbody-tr-class="text-center"
      :selectable="selectable"
      select-mode="multi"
      @row-clicked="contentChange"
      ref="orderListTable"
      responsive
      :fields="cengjicha"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="position-relative text-center"
    />
    <b-table
      v-if="geren == 2"
      bordered
      tbody-tr-class="text-center"
      :selectable="selectable"
      select-mode="multi"
      @row-clicked="contentChange"
      ref="orderListTable"
      responsive
      :fields="yjfk"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="position-relative text-center"
    />

    <b-table
      v-if="geren == 3"
      bordered
      tbody-tr-class="text-center"
      :selectable="selectable"
      select-mode="multi"
      @row-clicked="contentChange"
      ref="orderListTable"
      responsive
      :fields="yj_team"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="position-relative text-center"
    />
    <b-table
      v-if="geren == 4"
      bordered
      tbody-tr-class="text-center"
      :selectable="selectable"
      select-mode="multi"
      @row-clicked="contentChange"
      ref="orderListTable"
      responsive
      :fields="yj_record"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="position-relative text-center"
    />
    <b-table
      v-if="geren == 5"
      bordered
      tbody-tr-class="text-center"
      :selectable="selectable"
      select-mode="multi"
      @row-clicked="contentChange"
      ref="orderListTable"
      responsive
      :fields="gr_yj"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="position-relative text-center"
    />
    <b-modal
      id="audit-modal"
      cancel-variant="outline-secondary"
      ok-title="确认"
      cancel-title="取消"
      centered
      title="订单审核"
    >
      <validation-observer ref="auditForm">
        <b-form>
          <b-form-group label="订单TP值:">
            <validation-provider
              #default="{ errors }"
              name="订单TP"
              rules="required"
            >
              <b-form-input
                v-model="checkForm.order_tp"
                type="email"
                placeholder="请输入订单TP"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!--          <b-form-group>-->
          <!--            <label>订单状态</label>-->
          <!--            <validation-provider #default="{ errors }" name="审核状态" rules="required">-->
          <!--              <v-select-->
          <!--                v-model="checkForm.status"-->
          <!--                :reduce="item => item.value"-->
          <!--                :placeholder="'请选择状态'"-->
          <!--                :state="errors.length > 0 ? false : null"-->
          <!--                :class="[errors.length > 0 ? 'style-chooser' : 'style-chooser-none']"-->
          <!--                :options="[-->
          <!--                  { label: '已提交', value: 1 },-->
          <!--                  { label: '已处理', value: 2 },-->
          <!--                  { label: '已生效', value: 3 },-->
          <!--                ]" />-->
          <!--              <small class="text-danger">{{ errors[0] }}</small>-->
          <!--            </validation-provider>-->
          <!--          </b-form-group>-->
          <!--        改为单选按钮  -->
          <b-form-group label="订单状态:">
            <!--            <label>订单状态</label>-->
            <validation-provider
              #default="{ errors }"
              name="审核状态"
              rules="required"
            >
              <b-form-radio-group
                v-model="checkForm.status"
                :state="errors.length > 0 ? false : null"
              >
                <!--                <b-form-radio value="1">已提交</b-form-radio>-->
                <b-form-radio value="2">已处理</b-form-radio>
                <b-form-radio value="3">已生效</b-form-radio>
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="佣金状态:">
            <!--            <label>订单状态</label>-->
            <validation-provider
              #default="{ errors }"
              name="佣金状态"
              rules="required"
            >
              <b-form-radio-group
                v-model="checkForm.count_cate"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-radio value="1">第一年佣金</b-form-radio>
                <b-form-radio value="2">续费佣金</b-form-radio>
                <b-form-radio value="3">超额佣金</b-form-radio>
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <!--   footer   -->
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click.prevent="$bvModal.hide('audit-modal')"
        >取消</b-button>
        <b-button
          variant="primary" @click.prevent="auditInvoice"
        >确认</b-button>
      </template>
    </b-modal>

    <b-modal
      id="money-modal"
      cancel-variant="outline-secondary"
      ok-title="确认"
      cancel-title="取消"
      centered
      title="分担信息查看"
      size="lg"
    >
      <b-table
        :items="fenqianField"
        responsive
        :fields="fenqianFieldColumn"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
      />
      <!--   footer   -->
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click.prevent="$bvModal.hide('money-modal')"
        >关闭</b-button>
      </template>
    </b-modal>

    <b-modal
      id="delete-modal"
      cancel-variant="outline-secondary"
      ok-title="确认"
      cancel-title="取消"
      centered
      title="确认删除"
      size="sm"
    >
      请确认是否删除所选保单？
      <template #modal-footer>
        <b-button
          variant="danger" @click.prevent="confirmPLDelete"
        >确认</b-button>
        <b-button
          variant="secondary"
          @click.prevent="$bvModal.hide('delete-modal')"
        >关闭</b-button>
      </template>
    </b-modal>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span
            class="text-muted"
          >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
            {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRadio,
  BFormRadioGroup,
  BFormTimepicker,
  BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {
  reactive,
  getCurrentInstance,
  nextTick,
  ref,
} from '@vue/composition-api'
import { required } from '@core/utils/validations/validations'
import axiosIns from '@/libs/axios'
import useInvoicesList from './useOrderList'

import orderModule from './OrderManagerList'

export default {
  components: {
    BFormDatepicker,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormRadio,
    BFormRadioGroup,
  },
  filters: {
    filterStatusTxt(value) {
      switch (value) {
        case 1:
          return '已提交'
        case 2:
          return '已处理'
        case 3:
          return '已生效'
        default:
          return '未知'
      }
    },
    filterStatusColor(value) {
      switch (value) {
        case 1:
          return 'primary'
        case 2:
          return 'success'
        case 3:
          return 'success'
        default:
          return 'danger'
      }
    },
  },
  data() {
    return {
      geren: 0,
    }
  },
  methods: {
    one(e) {
      this.geren = e
      axiosIns.get('/finance/money').then(response => {
        console.log(response)
      })
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'user-order'
    const { proxy } = getCurrentInstance()
    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, orderModule)

    const {
      startTime,
      endTime,
      auditInvoice: auditInvoiceStore,
      fetchInvoices,
      tableColumns,
      yjfk,
      yj_team,
      yj_record,
      cengjicha,
      gr_yj,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      orderListTable,
      deleteInvoice,
      refetchData,
    } = useInvoicesList(proxy)
    const dateQuery = ref('')
    const checkForm = reactive({
      order_tp: '',
      status: '',
      id: '',
      count_cate: '1',
    })

    const auditInvoice = () => {
      proxy.$refs.auditForm.validate().then(isOk => {
        if (isOk) {
          if (Array.isArray(checkForm.id)) {
            proxy.$bvToast.toast('功能暂未开放')
            // axiosIns
            //   .put('/order/apply-all', {
            //     ids: checkForm.id,
            //     order_tp: checkForm.order_tp,
            //     status: checkForm.status,
            //     count_cate: checkForm.count_cate,
            //   })
            //   .then(res => {
            //     if (res.data.code === 0) {
            //       refetchData()
            //       proxy.$bvModal.hide('audit-modal')
            //     }
            //   })
          } else {
            auditInvoiceStore(checkForm)
          }
        } else {
          console.log('error')
        }
      })
    }
    const fenqianField = reactive([])
    const fenqianFieldColumn = [
      {
        key: 'user_id',
        label: '会员id',
      },
      {
        key: 'real_name',
        label: '会员名称',
      },
      {
        key: 'level',
        label: '会员等级',
      },
      {
        key: 'share_rate',
        label: '分担比例',
        formatter: (value, row) => `${value}%`,
      },
      {
        key: 'tp',
        label: '分担TP',
        formatter: (value, row) => `${value}`,
      },
      {
        key: 'money',
        label: '分担金额',
      },
      {
        label: '状态',
        key: 'stu_name',
      },
    ]

    const lock = ref(false)
    const getMoneyInfo = (item, id) => {
      document.body.style.cursor = 'wait'
      if (lock.value) return
      lock.value = true
      setTimeout(() => {
        axiosIns
          .get('/order/getRateInfo', { params: { order_id: id } })
          .then(response => {
            if (response.code == 0) {
              proxy.$bvModal.show('money-modal')
              nextTick(() => {
                fenqianField.splice(0, fenqianField.length, ...response.data)
              })
              lock.value = false
              document.body.style.cursor = 'default'
            } else {
              document.body.style.cursor = 'default'
              lock.value = false
              proxy.$bvToast.toast(response.msg, {
                title: '提示',
                variant: 'danger',
                solid: true,
                appendToast: true,
                autoHideDelay: 2000,
              })
            }
          })
          .catch(() => {
            lock.value = false
            document.body.style.cursor = 'default'
            proxy.$bvToast.toast('网络错误', {
              title: '提示',
              variant: 'danger',
              solid: true,
              appendToast: true,
              autoHideDelay: 2000,
            })
          })
      }, 1000)
    }

    const setItemEdit = data => {
      store.commit('user-order/setOrderDetail', data.item)
      setTimeout(() => {
        proxy.$router.push({
          name: 'policy_list_edit',
        })
      }, 500)
    }

    const setItemDetail = data => {
      store.commit('user-order/setOrderDetail', data.item)
      setTimeout(() => {
        proxy.$router.push({
          name: 'pay_detail',
          query: {
            id: data.item.id,
          },
        })
      }, 500)
    }

    const setItemEditCreate = data => {
      store.commit('user-order/setOrderDetail', data.item)
      setTimeout(() => {
        proxy.$router.push({
          name: 'policy_list_add_money',
        })
      }, 500)
    }

    const selectable = ref(true)

    const piliangForm = reactive({
      id: '',
    })
    const piliangArray = ref([])
    const contentChange = (item, index, event) => {
      if (piliangArray.value.indexOf(item.id) == -1) {
        piliangArray.value.push(item.id)
      } else {
        piliangArray.value.splice(piliangArray.value.indexOf(item.id), 1)
      }
    }

    const deleteInvoicePl = () => {
      if (piliangArray.value.length == 0) {
        proxy.$bvToast.toast('请选择要删除的订单', {
          title: '提示',
          variant: 'danger',
          solid: true,
          appendToast: true,
          autoHideDelay: 2000,
        })
      } else {
        // 弹出是否删除提示框
        proxy.$bvModal.show('delete-modal')
      }
    }
    const confirmPLDelete = () => {
      axiosIns
        .delete('/order/delete-all', { params: { ids: piliangArray.value } })
        .then(response => {
          if (response.code == 0) {
            proxy.$bvModal.hide('delete-modal')
            proxy.$bvToast.toast('删除成功', {
              title: '提示',
              variant: 'success',
              solid: true,
              appendToast: true,
              autoHideDelay: 2000,
            })
            refetchData()
          } else {
            proxy.$bvModal.hide('delete-modal')
            proxy.$bvToast.toast(response.msg, {
              title: '提示',
              variant: 'danger',
              solid: true,
              appendToast: true,
              autoHideDelay: 2000,
            })
          }
        })
    }

    return {
      startTime,
      endTime,
      setItemEditCreate,
      confirmPLDelete,
      deleteInvoicePl,
      piliangArray,
      piliangForm,
      contentChange,
      setItemDetail,
      selectable,
      setItemEdit,
      fenqianField,
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      orderListTable,
      yjfk,
      yj_team,
      yj_record,
      cengjicha,
      gr_yj,
      refetchData,

      deleteInvoice,
      auditInvoice,
      checkForm,
      required,
      getMoneyInfo,
      fenqianFieldColumn,
      dateQuery,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
.wocc {
  >>> .dropdown-toggle {
    padding: 0px !important;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.tab_head {
  padding: 10px;
  margin-right: 10px;
}
</style>
