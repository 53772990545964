/** @format */
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    orderDetail: {},
  },
  mutations: {
    setOrderDetail(state, data) {
      state.orderDetail = data
    },
  },
  actions: {
    fetchListData(ctx, param) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('/order/list', { params: param })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 添加
    orderCreate(ctx, param) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('/order/create', param)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 删除
    orderDelete(ctx, param) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete('/order/delete', { data: { id: param } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 修改
    orderUpdate(ctx, param) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put('/order/edit', param)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 审核
    orderAudit(ctx, param) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put('/order/apply', param)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
